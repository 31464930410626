html {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
}

* {
  max-height: 100000em;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  color: #1d1d24;
  font: 14px/1.7 'Montserrat', Arial, Helvetica, sans-serif;
  background: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}

img {
  border-style: none;
  vertical-align: top;
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #000;
  outline: none;
  transition: background 0.3s ease, color 0.3s ease, border 0.3s ease, opacity 0.3s ease;
}

input,
textarea,
select {
  color: #000;
}

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top;
}

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button {
  outline: none;
}

button[type="submit"],
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: #9b111e;
  border: 2px solid #9b111e;
  padding: 9px 25px;
  border-radius: 6px;
  display: inline-block;
  font-weight: 500;
  -webkit-transition: .3s;
  transition: .3s;
}

button[type="submit"]:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  background: #ebced1;
  border-color: #ebced1;
  color: #9b111e;
  box-shadow: 0 10px 15px 0 rgba(0,0,0,.1);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
textarea {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	border: none;
	padding: 0.4em 0.7em;
	width: 100%;
	outline: none;
	background: #f6f6f6;
	border-radius: 5px;
	height: 46px;
	font-size: 16px;
}

input[type="search"] {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

q {
  quotes: none;
}

q:before,
q:after {
  content: '';
  content: none;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	margin: 0 0 0.4em;
	font-weight: 700;
	line-height: 1.2;
}

h1, .h1 {
	font-size: 24px;
}

h2, .h2 {
	font-size: 20px;
}

h3, .h3 {
	font-size: 20px;
}

h4, .h4 {
	font-size: 18px;
}

p {
  margin: 0 0 1.8em;
}

ul {
  margin: 0 0 1.5em;
  padding: 0 0 0 20px;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.row {
  margin: 0 -15px;
}

.col {
  padding: 0 15px;
}

.bg-grey {
  background: #f7f8fa;
}

.btn {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: #9b111e;
  border: 2px solid #9b111e;
  padding: 9px 25px;
  border-radius: 6px;
  display: inline-block;
  font-weight: 500;
  -webkit-transition: .3s;
  transition: .3s;
}

.btn:hover{
  background: #ebced1;
  border-color: #ebced1;
  color: #9b111e;
  box-shadow: 0 10px 15px 0 rgba(0,0,0,.1);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.btn-border {
  /* color: #c6c9d8; */
  color: #fff;
  background: transparent;
  border-color: #b1b4c1;
}

.btn-border:hover{
  border-color: #9b111e;
  background: #9b111e;
  color: #fff;
}

.heading-block {
  max-width: 600px;
  margin: 0 auto 40px;
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.no-paddingtop {
  padding-top: 0 !important;
}

.no-paddingbottom {
  padding-bottom: 0 !important;
}

.form-wrapper {}

.form-wrapper .form-fields{}

.form-wrapper .form-field{
  margin-bottom: 15px;
}

.form-wrapper .form-error,
.form-wrapper .gf-label{
  display: none;
}

.form-wrapper .error-message {
  display: block;
  font-size: 14px;
  color: #f44336;
  margin-top: 5px;
}

@media only screen and (min-width: 1024px) {
  h1, .h1 {
    font-size: 65px;
  }

  h2, .h2 {
    font-size: 46px;
  }

  h3, .h3 {
    font-size: 36px;
  }

  h4, .h4 {
    font-size: 28px;
  }

  .btn {
    padding: 15px 40px;
  }
}