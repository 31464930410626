.app {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background:#ebced1;
  z-index: 9999;
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;
}

.preloader > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* .loaded .preloader{
  visibility: hidden;
  opacity: 0;
} */

.preloader img {
  max-width: 80px;
  animation: zoominout 1s infinite;
}

@keyframes zoominout {
  0% {
      transform: scale(1,1);
  }
  50% {
      transform: scale(1.5,1.5);
  }
  100% {
      transform: scale(1,1);
  }
}

@keyframes spin {
  0% {
      transform: rotate(0);
  }
  100% {
      transform: rotate(360deg);
  }
}


.container {
  max-width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 0;
  z-index: 99;
  transition: padding 0.3s ease, background 0.3s ease;
  /* bottom: 0; */
}

.sticky #header {
  padding: 10px 0;
  /* background-color: rgba(#1f1f25,0.9); */
  background-color: rgba(31,31,37, 0.9);
}

.logo {
  max-width: 150px;
}

.logo img{
  max-height: 45px;
}

.nav-opener {
  position: absolute;
  top: 50%;
  right: 20px;
	width: 24px;
	height: 16px;
	display:inline-block;
	vertical-align:middle;
	text-indent: -999px;
	/*overflow: hidden;*/
  z-index: 999;
  transform: translateY(-50%);
}

.nav-opener:before,
.nav-opener span,
.nav-opener:after {
	width: 100%;
	height: 2px;
	position: absolute;
	top: 7px;
	left: 0;
	background: #fff;
	border-radius: 1px;
	transition: opacity 0.3s ease;
}

.nav-opener:before,
.nav-opener:after {
	content:"";
	top: 0;
}

.nav-opener:after {
	top: 14px;
}

.nav-active {
  overflow: hidden;
}

.nav-active .nav-opener span {
	opacity: 0;
}

.nav-active .nav-opener:before,
.nav-active .nav-opener:after{
	content:"";
	position: absolute;
	top: 9px;
	left: 0;
	width: 100%;
	height: 2px;
	background: #000;
}

.nav-active .nav-opener:before{
	transform: rotate(45deg);
}

.nav-active .nav-opener:after{
	transform: rotate(-45deg);
}

.nav-active #nav .drop {
  right: 0;
}

#nav .drop {
  background: #fff;
  position: fixed;
  top: 0;
  right: -280px;
  bottom: 0;
  width: 280px;
  transition: right 0.3s linear;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 50px 0 30px;
}

#nav .drop ul {
  list-style: none;
  margin: 0;
  padding: 0 20px;
}

#nav .drop ul li {
  font-size: 16px;
}

#nav .drop ul li + li {
  margin-top: 10px;
}

#nav .drop ul li a{
  font-weight: 700;
}

#nav .drop ul li.active a,
#nav .drop ul li:hover a {
  color: #9b111e;
}

.banner-slider .banner-text{
  max-width: 555px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.banner-slider .slide {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color:#333;
  color: #fff;
  outline: none;
  text-align: center;
}

.banner-slider .slide img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.banner-slider .slide .container{
  height: 100%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-slider .slide .title {
  text-transform: uppercase;
  font-weight: 900;
}

.banner-slider .slide .description {
  font-weight: 300;
}

.banner-slider .slick-slide.slick-current .title {
  -webkit-animation: textUp 0.8s ease-in-out 0s 1 normal none running;
  animation: textUp 0.8s ease-in-out 0s 1 normal none running;
}

.banner-slider .slick-slide.slick-current .description {
  -webkit-animation: textUp 1s ease-in-out 0s 1 normal none running;
  animation: textUp 1s ease-in-out 0s 1 normal none running;
}

.banner-slider .slick-slide.slick-current .btn {
  -webkit-animation: textUp 1.2s ease-in-out 0s 1 normal none running;
  animation: textUp 1.2s ease-in-out 0s 1 normal none running;
}

.banner-slider .slide .btn {
  border-color: #fff;
}

.banner-slider .slide .btn:hover {
  border-color: #9b111e;
}

.slick-slide {
  outline: none;
}

.slick-arrow {
  display: none !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  border:2px solid #fff;
  outline: none;
  border-radius: 50%;
  color: transparent;
  background: transparent;
  z-index: 1;
  transition: all 0.3s ease;
}

.slick-arrow:before{
  content:"";
  position: absolute;
  top: 18px;
  left: 21px;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 2px 2px 0 0;
  border-color: #fff #fff transparent transparent;
  transition: border 0.3s ease;
}

.slick-arrow.slick-prev{
  left: 40px;
}

.slick-arrow.slick-prev:before{
  transform: rotate(-136deg);
}

.slick-arrow.slick-next{
  right:40px;
}

.slick-arrow.slick-next:before{
  left: 14px;
  transform: rotate(45deg);
}

.slick-arrow:hover {
  border-color: #9b111e;
  background-color: #9b111e;
}

.slick-arrow.slick-prev:hover {
  left: 30px;
}

.slick-arrow.slick-next:hover {
  right: 30px;
}

/* .slick-arrow:hover:before {
  border-color: #9b111e #9b111e transparent transparent;
} */

.slick-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  max-width: 500px;
  margin:0 auto;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  display: inline-block;
  margin: 0 10px;
}

.slick-dots li.slick-active button{
  border-color: #9b111e;
  background: transparent;
  /* width: 10px;
  height: 10px; */
  transform: scale(2);
  transition: transform 0.3s ease;
}

.slick-dots li button {
  border:none;
  outline:none;
  font-size: 0;
  line-height: 0;
  padding: 2px;
  background: #fff;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  border: 1px solid transparent;
}

@keyframes textUp{
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0,50%,0);
    transform: translate3d(0,50%,0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.inner-banner {
  min-height: 300px;
  padding: 150px 0 30px;
  text-align: center;
  background-color: #333;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff
}

.inner-banner .container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.inner-banner .title {
  /* background: #9b111e;
  background: -webkit-linear-gradient(305deg,#9b111e,#ebced1);
  background: linear-gradient(145deg,#9b111e,#ebced1); */
  background: #f81f01;
  background: -webkit-linear-gradient(305deg,#f81f01,#ee076e);
  background: linear-gradient(145deg,#f81f01,#ee076e);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  font-size: 50px;
}

.inner-banner p {
  margin: 20px 0 0;
}

.inner-banner .btn {
  margin-top: 20px;
}

.section{
  padding: 60px 0;
}


.listing-block .col,
.news-listing .col,
.latest-news-block .col,
.projects-block .col,
.column-with-icon .col {
  width: 100%;
}

.listing-block .col {
  text-align: center;
}

.listing-block .col,
.news-listing .col,
.column-with-icon .col {
  margin-bottom: 40px;
}

.listing-block .col-wrap,
.column-with-icon .col-wrap {
  padding: 30px;
  border-radius: 5px;
  transition: box-shadow 0.3s ease;
  height: 100%;
  position: relative;
}


.listing-block .col-wrap .link,
.column-with-icon .col-wrap .link {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.listing-block .col-wrap:hover,
.column-with-icon .col-wrap:hover{
  box-shadow: 0 0 9px rgba(0,0,0,0.3);
}

.listing-block .col .title,
.column-with-icon .col .title {
  font-size: 20px;
}


.listing-block .col .icon-wrap,
.column-with-icon .col .icon-wrap {
  margin-bottom: 10px;
  height: 50px;
  white-space: nowrap;
}

.listing-block .col .icon-wrap:after,
.column-with-icon .col .icon-wrap:after {
  content:"";
  display: inline-block;
  vertical-align: top;
  height: 50px;
}

.listing-block .icon-wrap img,
.column-with-icon .col .icon-wrap img{
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  max-height: 50px;
  width: 100%;
}

.listing-block .col p,
.column-with-icon .col p {margin:0;}

.projects-block {
  background-color: #f7f8fa;
}

.brand-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.brand-list li {
  min-height: 150px;
  white-space: nowrap;
  width: 50%;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.brand-list li:after {
  content:"";
  display: inline-block;
  vertical-align: middle;
  height: 150px;
}

.brand-list li:hover {
  background-color: #9b111e;
  transform: scale(1.05);
  border-radius: 5px;
}

.brand-list li:hover img{
  filter: brightness(0) invert(1);
}

.brand-list li img{
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  max-height: 100px;
  transition: all 0.3s ease;
}

#footer {
  background:#1c1d23;
  padding: 20px 0;
  color: #fff;
  text-align: center;
}

#footer a{
  color: #fff;
}

/* #footer p{margin: 0;} */

.footer-logo {
  margin-bottom: 20px;
}

.footer-logo img{
  max-width: 150px;
  max-width: 60px;
}

.footer-top {margin: 0 0 30px;}

.footer-top .row {
  width: 100%;
}

.footer-top .col {
  width: 100%;
}

.footer-top .social-networks {
  margin: 0;
  padding: 0;
  list-style: none;

}

.footer-top .social-networks li{
  display: inline-block;
  vertical-align: top;
}

.footer-top .social-networks li + li {
  margin-left: 15px;
}

.footer-top .social-networks a{
  color: #fff;
  font-size: 0;
}

.footer-top .social-networks a .fa{
  font-size: 24px;
}

.footer-top .title {
  display: block;
  margin-bottom: 15px;
  font-size: 20px;
}


.footer-bottom p {margin: 0;}

@media only screen and (min-width: 768px) {
  .slick-arrow {
    display: block !important;
  }

  .latest-news-block .col,
  .projects-block .col,
  .column-with-icon .col {
    width: 50%;
  }

  .brand-list {
    border-left: 1px solid rgba(0,0,0,.05);
    border-bottom: 1px solid rgba(0,0,0,.05);
  }

  .brand-list li {
    width: 33.33%;
    min-height: 214px;
    border-top: 1px solid rgba(0,0,0,.05);
    border-right: 1px solid rgba(0,0,0,.05);
  }

  .brand-list li:after {
    height: 214px;
  }

  .brand-list li img {
    max-height: 110px;
    max-width: 150px;
  }

  .footer-top .col.col-logo {
    width: 40%;
  }

  .footer-top .col.col-text {
    width: 60%;
  }

  .listing-block .col{
    width: 33.33%;
  }
}

@media only screen and (min-width: 1024px) {
  #header {
    padding: 40px 0;
  }

  .nav-opener {
    display: none;
  }

  #header .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .logo{
    width: 150px;
  }

  .logo img{
    /* max-height: 100%; */
    max-height: 80px;
    transition: all 0.3s ease;
  }

  .sticky .logo img {
    max-width: 50px;
  }

  #nav {
    width: calc(100% - 150px);
    padding-left: 30px;
  }

  #nav .drop {
    position: static;
    width: 100%;
    background: transparent;
    padding: 0;
    min-height: 0;
  }

  #nav .drop ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  #nav .drop ul li {
    margin-left: 30px;
  }

  #nav .drop ul li + li {
    margin: 0 0 0 30px;
  }

  #nav .drop ul li a{
    color: #fff;
  }

  .banner-slider .slide .container {
    min-height: 600px;
  }

  .banner-slider .banner-text{
    max-width: 800px;
  }

  .banner-slider .slide .description {
    font-size: 24px;
    line-height: 1.5;
  }

  .news-listing .col,
  .latest-news-block .col,
  .projects-block .col,
  .column-with-icon .col {
    width: 33.33%;
  }

  #footer {
    text-align: left;
    padding-top: 40px;
  }

  .footer-top .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer-bottom {
    text-align: center;
  }
}